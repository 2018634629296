@import '../../../assets/scss/globals';

$language-switch-height: 2.25em;
$language-button-height: 1.25em;

.LanguageSwitcher {
  background: $color-white-smoke-darker;

  [class*='global-content-wrapper'] {
    padding: $global-padding-small var(--global-content-horizontal-spacing);
    height: $language-switch-height;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .LanguageButtonSet {
    align-items: center;
  }

  .LanguageButton {
    @include text-small();
    display: flex;
    justify-content: center;
    height: $language-button-height;
    min-height: $language-button-height;
    width: auto;
    min-width: 1.625em;
    padding: 1rem 1em;
    color: $color-charcoal;
    background: none;
    border: 0;
    box-shadow: none !important;
    position: relative;
    text-transform: uppercase;

    &:active {
      border: 0;
    }

    &:focus {
      border: 0;
    }

    &:first-child::after {
      content: '';
      position: absolute;
      right: 0;
      border-right: 1px solid $color-suva-grey;
      height: 80%;
    }

    &_active {
      @include text-small(bold);
      text-transform: uppercase;
    }
  }
}
