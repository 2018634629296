@import '../../../assets/scss/globals';

$footer-max-width: $global-max-width-raw;
$footer-max-breakpoint: 1400px;
$footer-social-icon-size: 1rem;
$footer-margin-small: $global-margin-small;
$footer-margin-medium: $global-margin-medium;
$footer-margin-large: $global-margin-large;

$footer-padding-small: $global-padding-small;
$footer-padding-medium: $global-padding-medium;
$footer-padding-large: $global-padding-large;
$footer-padding-extra-large: $global-padding-extra-large;

$footer-background-color: #0a2d57;
$footer-background-color-dark: $color-extra-dark-blue;
$footer-font-color: $color-white;

%nav-max-size {
  width: 100%;
  max-width: $footer-max-width;
  margin: 0 auto;
}

%icon-base {
  line-height: 0 !important;
  font-size: 0 !important;
  min-height: $footer-social-icon-size;
  min-width: $footer-social-icon-size;

  &::before {
    position: absolute;
    min-height: $footer-social-icon-size;
    min-width: $footer-social-icon-size;
    height: $footer-social-icon-size;
    width: $footer-social-icon-size;
    content: '';
  }

  &:focus {
    outline: 0 !important;

    &::before {
      outline: 2px solid $color-royal-blue;
    }
  }
}

.Footer {
  a::after {
    background: url('../../../assets/svg/extlink.svg') center no-repeat;
    padding-right: 10px;
    content: '';
    margin-left: 5px;
    margin-right: 3px;
    display: inline-block;
    height: 10px;
    width: 0;
  }

  [class*='navi'] {
    @extend %nav-max-size;

    display: flex;
    flex-flow: column nowrap;
    row-gap: 1.5rem;

    > div {
      // remove clearfix div
      display: none;
    }
  }

  .FooterNavSection {
    background-color: $footer-background-color;
    padding: var(--global-default-vertical-spacing) var(--global-content-horizontal-spacing);

    a {
      color: $footer-font-color;
      line-height: 1.3rem;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        outline: 2px solid $footer-font-color;
      }

      &::after {
        background: url('../../../assets/svg/extlink_white.svg') center no-repeat;
      }
    }

    ul {
      line-height: 1.4rem;
    }

    ul[class*='level_1'] {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;


      > li {
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 0;
        row-gap: 0.5rem;
      }

      [class*='pagetitle'] {
        flex: 0 0 5rem;
        margin-right: 0.5rem;

        > a {
          @include text-extra-small(bold);
          hyphens: auto;

          &::after {
            display: none;
          }
        }
      }

      ul[class*='level_2'] {
        display: flex;
        flex-flow: row wrap;
        column-gap: 1.25rem;
        row-gap: 0.5rem;

        > li {
          display: inline;

          > a {
            @include text-extra-small;
          }
        }
      }
    }

    li {
      text-align: start;
    }
  }

  .FooterMetaSection {
    background-color: $footer-background-color-dark;
    padding: var(--global-default-vertical-spacing) var(--global-content-horizontal-spacing);
    display: flex;

    .FooterMetaContent {
      @extend %nav-max-size;
      display: flex;
      flex-wrap: wrap;
      gap: $global-margin-small;

      @media screen and (min-width: $breakpoint-tablet) {
        padding-left: $global-margin-extra-extra-large;
      }

      @media screen and (min-width: $footer-max-breakpoint) {
        padding-left: 0;
      }

      ul {
        flex: 1 1 auto;
        line-height: 1.1rem;
        display: flex;
      }

      li {
        + li {
          margin-left: 1.25rem;
        }

        a {
          @include text-extra-small;
          color: $footer-font-color;

          &::after {
            display: none;
          }

          &:focus {
            outline: 2px solid $color-royal-blue;
          }
        }
      }

      .SocialLinks {
        justify-content: flex-end;

        li {
          min-width: 18px;

          a {
            &::before {
              background-size: $footer-social-icon-size $footer-social-icon-size;
            }
          }

          + li {
            margin-left: $footer-margin-large;
          }
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-desktop) {
    .FooterNavSection {
      ul[class*='level_1'] {
        > li {
          flex-direction: column;

          [class*='pagetitle'] {
            flex: 0 0 auto;
            margin-right: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    .FooterMetaSection {
      .FooterMetaContent {
        flex-direction: column;
        gap: $global-margin-medium;
        align-items: center;

        ul {
          justify-content: center;
        }
      }
    }
  }
}

.IconRSS {
  @extend %icon-base;

  &::before {
    background: url('../../../assets/svg/rss_white.svg') center no-repeat;
  }
}

.IconFB {
  @extend %icon-base;

  &::before {
    background: url('../../../assets/svg/facebook_white.svg') center no-repeat;
  }
}

.IconIG {
  @extend %icon-base;

  &::before {
    background: url('../../../assets/svg/instagram_white.svg') center no-repeat;
  }
}

.IconYT {
  @extend %icon-base;

  &::before {
    background: url('../../../assets/svg/youtube_white.svg') center no-repeat;
  }
}

.IconTW {
  @extend %icon-base;

  &::before {
    background: url('../../../assets/svg/twitter_white.svg') center no-repeat;
  }
}

.IconLI {
  @extend %icon-base;

  &::before {
    background: url('../../../assets/svg/linkedin_white.svg') center no-repeat;
  }
}

.SupportFooter {
  display: flex;
  background: $color-gainsboro;
  justify-content: center;
  align-items: center;
  height: 7.5rem;
  flex-direction: column;
  padding: 0 var(--global-content-horizontal-spacing);

  .SupportFooterTitle {
    @include text-small(bold);
    color: $color-nightrider;
    margin-bottom: $global-margin-extra-small;
    text-align: center;
  }

  .SupportFooterSubTitle {
    @include text-small;
    color: $color-nightrider;
    margin-bottom: $global-margin-medium;
  }

  .SupportFooterContactInfo {
    display: flex;

    > * {
      @include text-small;
      color: $color-nightrider;
      display: flex;
      align-items: center;
    }

    > * + * {
      margin-left: $global-margin-medium;
    }

    svg {
      height: 1.25rem;
      fill: $color-lochmara;
      margin-right: $global-margin-small;
    }
  }

  @media screen and (max-width: $breakpoint-phone) {
    .SupportFooterTitle {
      @include text-extra-small(bold);
    }

    .SupportFooterSubTitle {
      @include text-extra-small;
    }
  }
}
